<template>
    <v-container grid-list-xl class="box">
        
        <!-- kriterij iskanja -->
            <v-layout row wrap align-left justify-space-around fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="card1 = !card1">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Zasedena parkirišča</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="full_occupancy" :label="`Prikaži samo parkirišča s polno zasedenostjo: ${checkboxFullOccupancyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Podjetje / oseba brez omejitev parkiranja</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults" class="ti" v-model="parking_restriction_off" :label="`Prikaži samo parkirišča / osebe brez omejitev parkiranja: ${checkboxParkingRestrictionLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                            </v-card-text>
                            <v-divider v-if="card1">
                            </v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn><br>
                                <v-btn v-if="checkApplicationPermissions([])"   @click="exportData()" color="secondary" dark  block outlined style=""><v-icon left>mdi-export</v-icon>Prikaz Izvoz&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                                    <v-btn v-if="checkApplicationPermissions([])"  @click="exportData()" color="secondary" dark text><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                    </v-card>
                </v-flex>
            </v-layout>
            <br>


        <parking-occupancy-data-table
        :settings="dataTableSettings"
        :queryParameters="queryParameters"
        :boxedLayout="true"
        ></parking-occupancy-data-table> 

        <data-table-toggle-columns
        :dataTableId="dataTableSettings.id"
        :columns="dataTableSettings.headers"
        dataTableColumnsMenuId="toggle-occupancy-list-columns"

        ></data-table-toggle-columns>
    </v-container>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDategetUserApplicationRole, getUserApplicationPermissions, checkPermissions, getAppSettingsFromLocalStorage} from '@/helpers/utilities'
const ParkingOccupancyDataTable = () => import('@/components/ParkingOccupancyDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { isMobileDevice } from '@/helpers/utilities.js'
import { addExportToQueue } from '@/helpers/services.js'

export default {
    components: {
        ParkingOccupancyDataTable,
        DataTableToggleColumns
    },
    name: 'root-parking-occupancy',
    data: () => ({

        card1: true,
        card2: true,

        full_occupancy: false,
        parking_restriction_off: false,

        dataTableSettings: {
            id: 'dtParkingOccupancy',
            title: 'Zasedenost parkirišč',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true },
                { text: "Parkirišče", value: "name", class: "text-xs-left", visibility: true },
                { text: "Parkirna mesta", value: "number_of_parking_spaces", class: "text-xs-left", visibility: true },
                { text: "Zasedenost", value: "occupancy", class: "text-xs-left", visibility: true },
                { text: "Zasedena mesta", value: "occupied_parking_spaces", class: "text-xs-left", visibility: true },
                { text: "Prosta mesta", value: "free_parking_spaces", class: "text-xs-left", visibility: true },
                { text: "Omejitev", value: "parking_restriction", class: "text-xs-left", visibility: true },
                { text: "Čas osvežitve", value: "updated_at_formatted", class: "text-xs-left", visibility: true },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true, permissions:[]},
            ],
            updateInterval: true,
            rowsPerPage: 25,
            endpoint: 'celovska-lj/unit-parking-occupancy',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_occupancy',
                totalItems : {
                    event: ''
                },
                search: 'dt-occupancy-search',
                toggleColumnsId: 'toggle-occupancy-list-columns'
            }


        }
    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                fullOccupancy: this.full_occupancy,
                parkingRestrictionOff: this.parking_restriction_off
            }
        },

        checkboxFullOccupancyLabel() {
            if(this.full_occupancy) {
                return 'DA'
            }

            return 'NE'
        },

        checkboxParkingRestrictionLabel() {
            if(this.parking_restriction_off) {
                return 'DA'
            }

            return 'NE'
        }
    },

    methods: {
        checkApplicationPermissions(requiredPermissions) {
            return checkPermissions(requiredPermissions, getUserApplicationPermissions())
        },

        exportData() {
            window.console.log("exporting data ...");
        },

        displayResults() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 280)
        },

        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        }
    },

    created() {
        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_parking_occupancy",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }

        if(isMobileDevice(this)) {
            this.card1 = false
        }
    },

    mounted() {

    },

    destroyed() {
        EventBus.$off(this.dataTableSettings.id)
    }
}

</script>

<style scoped>

</style>